import _export from "../internals/export";
import _iterate from "../internals/iterate";
import _createProperty from "../internals/create-property";
var $ = _export;
var iterate = _iterate;
var createProperty = _createProperty;

// `Object.fromEntries` method
// https://github.com/tc39/proposal-object-from-entries
$({
  target: "Object",
  stat: true
}, {
  fromEntries: function fromEntries(iterable) {
    var obj = {};
    iterate(iterable, function (k, v) {
      createProperty(obj, k, v);
    }, {
      AS_ENTRIES: true
    });
    return obj;
  }
});
export default {};